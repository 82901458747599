import { createSlice } from "@reduxjs/toolkit"
import { getCurrentPayment, updateMonthlyRemittance, updatePMSSmsPayment, updatePMSSubscriptionRenewal, updatePMSSubscriptionUpgrade, updateSmsPayment, updateSubscriptionRenewal, verifyCallbackPayment } from "../actions/payment.callback.action"


const paymentCallbackSlice = createSlice({
    name: 'payment_callback',
    initialState: {
        payment: null,
        callback_payment: null,
        callback_payment_status: null,
        paymentCallbackLoading: false,
    },
    extraReducers: {
        [verifyCallbackPayment.pending]: (state, action) => {
            state.paymentCallbackLoading = true
        },
        [verifyCallbackPayment.fulfilled]: (state, action) => {
            state.paymentCallbackLoading = false
            state.callback_payment_status = action.payload
        },
        [verifyCallbackPayment.rejected]: (state, action) => {
            state.paymentCallbackLoading = false
        },
       
        [getCurrentPayment.pending]: (state, action) => {
            state.paymentCallbackLoading = true
        },
        [getCurrentPayment.fulfilled]: (state, action) => {
            state.paymentCallbackLoading = false
            state.callback_payment = action.payload
        },
        [getCurrentPayment.rejected]: (state, action) => {
            state.paymentCallbackLoading = false
        },
       
        [updateSmsPayment.pending]: (state, action) => {
            state.paymentCallbackLoading = true
        },
        [updateSmsPayment.fulfilled]: (state, action) => {
            state.paymentCallbackLoading = false
            state.payment = action.payload
        },
        [updateSmsPayment.rejected]: (state, action) => {
            state.paymentCallbackLoading = false
        },
       
        [updatePMSSmsPayment.pending]: (state, action) => {
            state.paymentCallbackLoading = true
        },
        [updatePMSSmsPayment.fulfilled]: (state, action) => {
            state.paymentCallbackLoading = false
            state.payment = action.payload
        },
        [updatePMSSmsPayment.rejected]: (state, action) => {
            state.paymentCallbackLoading = false
        },
       
        [updateSubscriptionRenewal.pending]: (state, action) => {
            state.paymentCallbackLoading = true
        },
        [updateSubscriptionRenewal.fulfilled]: (state, action) => {
            state.paymentCallbackLoading = false
            state.payment = action.payload
        },
        [updateSubscriptionRenewal.rejected]: (state, action) => {
            state.paymentCallbackLoading = false
        },
       
        [updatePMSSubscriptionRenewal.pending]: (state, action) => {
            state.paymentCallbackLoading = true
        },
        [updatePMSSubscriptionRenewal.fulfilled]: (state, action) => {
            state.paymentCallbackLoading = false
            state.payment = action.payload
        },
        [updatePMSSubscriptionRenewal.rejected]: (state, action) => {
            state.paymentCallbackLoading = false
        },
       
        [updatePMSSubscriptionUpgrade.pending]: (state, action) => {
            state.paymentCallbackLoading = true
        },
        [updatePMSSubscriptionUpgrade.fulfilled]: (state, action) => {
            state.paymentCallbackLoading = false
            state.payment = action.payload
        },
        [updatePMSSubscriptionUpgrade.rejected]: (state, action) => {
            state.paymentCallbackLoading = false
        },
       
        [updateMonthlyRemittance.pending]: (state, action) => {
            state.paymentCallbackLoading = true
        },
        [updateMonthlyRemittance.fulfilled]: (state, action) => {
            state.paymentCallbackLoading = false
            state.payment = action.payload
        },
        [updateMonthlyRemittance.rejected]: (state, action) => {
            state.paymentCallbackLoading = false
        },
    }
})

export default paymentCallbackSlice.reducer