import { API } from "../config"


export const inspectCallbackPayment = (formData) => API.patch('/payment/verify_callback_payment/', formData)


export const fetchCallbackPayment = (reference) => API.get(`/payment/callback_payment/${reference}/`,)


export const patchSmsCallbackPayment = (formData) => API.patch(`/his/update_sms_payment/`, formData)


export const patchPMSSmsCallbackPayment = (formData) => API.patch(`/pharmacy/update_sms_payment/`, formData)


export const patchSubscriptionRenewalCallbackPayment = (formData) => API.patch(`/his/update_subscription_renewal_payment/`, formData)


export const patchPMSSubscriptionRenewalCallbackPayment = (formData) => API.patch(`/pharmacy/update_subscription_renewal_payment/`, formData)


export const patchPMSSubscriptionUpgradeCallbackPayment = (formData) => API.patch(`/pharmacy/update_subscription_upgrade_payment/`, formData)


export const patchMonthlyRemittanceCallbackPayment = (formData) => API.patch(`/his/update_monthly_remittance_payment/`, formData)