import React from 'react'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Payment, Home } from './pages'

const App = () => {
	return (
		<BrowserRouter>
			<ToastContainer />

			<Routes>
				<Route path="" element={<Home />} />

				<Route path="/cb_payments/verify" element={
					<Payment />
				} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
