import { createAsyncThunk } from "@reduxjs/toolkit"

import { fetchCallbackPayment, inspectCallbackPayment, patchMonthlyRemittanceCallbackPayment, patchPMSSmsCallbackPayment, patchPMSSubscriptionRenewalCallbackPayment, patchPMSSubscriptionUpgradeCallbackPayment, patchSmsCallbackPayment, patchSubscriptionRenewalCallbackPayment } from "../routes/payment.callback.routes"


export const verifyCallbackPayment = createAsyncThunk(
    'payment_callback/verifyCallbackPayment',
    async ({ formData }, { rejectWithValue }) => {
        try {
            const { data } = await inspectCallbackPayment(formData)

            return data.response.data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const getCurrentPayment = createAsyncThunk(
    'payment_callback/getCurrentPayment',
    async ({ reference }, { rejectWithValue }) => {
        try {
            const { data } = await fetchCallbackPayment(reference)

            return data
        } catch (error) {
            console.log(error.response)
            return rejectWithValue(null)
        }
    }
)


export const updateSmsPayment = createAsyncThunk(
    'payment_callback/updateSmsPayment',
    async ({ formData, toast }, { rejectWithValue }) => {
        try {
            const { data } = await patchSmsCallbackPayment(formData)

            toast.success("Payment verified successfully")
            
            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occured while updating sms payment, please try again.")
            return rejectWithValue(null)
        }
    }
)


export const updatePMSSmsPayment = createAsyncThunk(
    'payment_callback/updatePMSSmsPayment',
    async ({ formData, toast }, { rejectWithValue }) => {
        try {
            const { data } = await patchPMSSmsCallbackPayment(formData)

            toast.success("Payment verified successfully")
            
            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occured while updating sms payment, please try again.")
            return rejectWithValue(null)
        }
    }
)


export const updateSubscriptionRenewal = createAsyncThunk(
    'payment_callback/updateSubscriptionRenewal',
    async ({ formData, toast }, { rejectWithValue }) => {
        try {
            const { data } = await patchSubscriptionRenewalCallbackPayment(formData)

            toast.success("Payment verified successfully")
            
            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occured while updating subscription renewal payment, please try again.")
            return rejectWithValue(null)
        }
    }
)


export const updatePMSSubscriptionRenewal = createAsyncThunk(
    'payment_callback/updatePMSSubscriptionRenewal',
    async ({ formData, toast }, { rejectWithValue }) => {
        try {
            const { data } = await patchPMSSubscriptionRenewalCallbackPayment(formData)

            toast.success("Payment verified successfully")
            
            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occured while updating subscription renewal payment, please try again.")
            return rejectWithValue(null)
        }
    }
)


export const updatePMSSubscriptionUpgrade = createAsyncThunk(
    'payment_callback/updatePMSSubscriptionUpgrade',
    async ({ formData, toast }, { rejectWithValue }) => {
        try {
            const { data } = await patchPMSSubscriptionUpgradeCallbackPayment(formData)

            toast.success("Payment verified successfully")
            
            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occured while updating subscription renewal payment, please try again.")
            return rejectWithValue(null)
        }
    }
)


export const updateMonthlyRemittance = createAsyncThunk(
    'payment_callback/updateMonthlyRemittance',
    async ({ formData, toast }, { rejectWithValue }) => {
        try {
            const { data } = await patchMonthlyRemittanceCallbackPayment(formData)

            toast.success("Payment verified successfully")
            
            return data
        } catch (error) {
            console.log(error.response)
            toast.warning("An error occured while updating subscription renewal payment, please try again.")
            return rejectWithValue(null)
        }
    }
)