import React from 'react'

const Home = () => {
  return (
    <div className='h-screen grid place-items-center bg-slate-600'>
        <h1 className='text-white text-[50px]'>Zuum Pay</h1>
    </div>
  )
}

export default Home