/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import { PaymentAnimation1 } from '../../assets'
import { getCurrentPayment, updateMonthlyRemittance, updatePMSSmsPayment, updatePMSSubscriptionRenewal, updatePMSSubscriptionUpgrade, updateSmsPayment, updateSubscriptionRenewal, verifyCallbackPayment } from '../../state/actions/payment.callback.action'


const HisPayment = () => {
    const dispatch = useDispatch()

    const { payment, callback_payment, callback_payment_status } = useSelector(state => state.payment_callback)

    const [ref, setRef] = useState(null)

    useEffect(() => {
        const ref = window.location.href.split('reference=')[1]
        setRef(ref)
    }, [])

    // console.log(ref)

    useEffect(() => {
        if (ref) {
            dispatch(verifyCallbackPayment({ formData: { reference: ref } }))
        }
    }, [ref])

    useEffect(() => {
        if (callback_payment_status) {
            dispatch(getCurrentPayment({ reference: ref }))
        }
    }, [callback_payment_status])

    useEffect(() => {
        if (callback_payment?.title === "sms_payment") {
            const formData = {
                organization: callback_payment?.organization,
                amount: callback_payment?.amount,
                reference: ref,
                api_callback_url: callback_payment?.api_callback_url
            }
            dispatch(updateSmsPayment({ formData, toast }))
        }
        if (callback_payment?.title === "pms_sms_payment") {
            const formData = {
                organization: callback_payment?.organization,
                amount: callback_payment?.amount,
                reference: ref,
                api_callback_url: callback_payment?.api_callback_url
            }
            dispatch(updatePMSSmsPayment({ formData, toast }))
        }
        if (callback_payment?.title === "renew_subscription_payment") {
            const formData = {
                subtitle: callback_payment?.subtitle,
                organization: callback_payment?.organization,
                amount: callback_payment?.amount,
                reference: ref,
                mode_of_payment: callback_payment_status?.channel,
                authorization: callback_payment?.authorization,
                api_callback_url: callback_payment?.api_callback_url
            }
            // console.log(formData)
            dispatch(updateSubscriptionRenewal({ formData, toast }))
        }
        if (callback_payment?.title === "pms_renew_subscription_payment") {
            const formData = {
                title: callback_payment?.subtitle,
                organization: callback_payment?.organization,
                price: parseInt(callback_payment?.amount),
                amount_paid: parseInt(callback_payment?.amount),
                payment_reference: ref,
                mode_of_payment: callback_payment_status?.channel,
                authorization: callback_payment?.authorization,
                api_callback_url: callback_payment?.api_callback_url
            }
            // console.log(formData)
            dispatch(updatePMSSubscriptionRenewal({ formData, toast }))
        }
        if (callback_payment?.title === "pms_upgrade_subscription_payment") {
            const formData = {
                title: callback_payment?.subtitle,
                organization: callback_payment?.organization,
                price: parseInt(callback_payment?.amount),
                amount_paid: parseInt(callback_payment?.amount),
                payment_reference: ref,
                mode_of_payment: callback_payment_status?.channel,
                authorization: callback_payment?.authorization,
                api_callback_url: callback_payment?.api_callback_url
            }
            // console.log(formData)
            dispatch(updatePMSSubscriptionUpgrade({ formData, toast }))
        }
        if (callback_payment?.title === "monthly_remittance") {
            const formData = {
                organization: callback_payment?.organization,
                reference: ref,
                invoice: callback_payment?.invoice,
                mode_of_payment: callback_payment_status?.channel,
                authorization: callback_payment?.authorization,
                api_callback_url: callback_payment?.api_callback_url
            }
            // console.log(formData)
            dispatch(updateMonthlyRemittance({ formData, toast }))
        }
    }, [callback_payment])

    useEffect(() => {
        if(payment){
            window.location.href = payment?.redirect
        }
        // console.log(payment, '::  sms payment')
    }, [payment])


    return (
        <div className='h-screen grid place-items-center'>
            <img
                src={PaymentAnimation1}
                alt=""
            />
        </div>
    )
}

export default HisPayment